import * as ScrollArea from '@radix-ui/react-scroll-area';
import clsx from 'clsx';
import { forwardRef, ReactNode, useRef } from 'react';

type ScrollContainerProps = {
  className?: string;
  absolute?: boolean;
  dialog?: boolean;
  children: ReactNode;
};

export default forwardRef<HTMLDivElement, ScrollContainerProps>(
  ({ className, absolute, dialog, children }, ref) => {
    const viewport = useRef<HTMLDivElement>(null);

    return (
      <ScrollArea.Root
        ref={ref}
        className={clsx(
          absolute
            ? '!absolute left-0 top-0 right-0 bottom-0 h-auto w-auto'
            : 'relative h-full w-full',
          dialog ? 'top-[3rem]' : 'top-0',
        )}
      >
        <ScrollArea.Viewport
          ref={viewport}
          className={clsx('h-full w-full overflow-hidden', className)}
        >
          {children}
        </ScrollArea.Viewport>
        <ScrollArea.Scrollbar
          orientation="horizontal"
          className="m-1 flex h-2 rounded bg-gray-200/50 hover:bg-gray-200/75"
        >
          <ScrollArea.Thumb className="flex min-h-full rounded bg-gray-400/50" />
        </ScrollArea.Scrollbar>
        <ScrollArea.Scrollbar
          orientation="vertical"
          className="m-1 flex w-2 rounded bg-gray-200/50 hover:bg-gray-200/75"
        >
          <ScrollArea.Thumb className="flex min-w-full rounded bg-gray-400/50" />
        </ScrollArea.Scrollbar>
        <ScrollArea.Corner />
      </ScrollArea.Root>
    );
  },
);
